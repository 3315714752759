<template>
  <div v-if="localUser" class="fill-height overflow-y-auto">
    <div class="mx-auto" style="width: 640px; max-width: 640px;">
      <div class="d-flex flex-row align-center mt-2">
        <div class="subtitle-1" style="width: 100px">Имя</div>
        <v-text-field
            v-model="localUser.name"
            :rules="rulesName"
            outlined
            dense
            lang="ru"
            hide-details
            ref="name"
        />
      </div>
      <div class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px">Логин</div>
        <v-text-field
            v-model="localUser.login"
            :rules="rulesLogin"
            outlined
            dense
            lang="ru"
            hide-details
            ref="login"
        />
      </div>
      <div v-if="!isEdit" class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px">Пароль</div>
        <v-text-field
            v-model="localUser.password"
            :rules="rulesPassword"
            :type="isShowPassword ? 'text' : 'password'"
            :append-icon="isShowPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="isShowPassword = !isShowPassword"
            outlined
            dense
            lang="ru"
            hide-details
            ref="password"
        />
      </div>
      <div class="d-flex flex-row align-center mt-4" v-if="companies && companies.length">
        <div class="subtitle-1" style="width: 100px">Компания</div>
        <v-autocomplete
          v-model="localUser.company_id"
          :items="companies"
          outlined
          dense
          lang="ru"
          hide-details
          hide-no-data
          ref="companyId"
          item-text="name"
          item-value="id"
        />
      </div>
      <v-btn-toggle
          v-model="roleIndex"
          color="primary"
          class="mt-4 mb-2"
          style="width: 100%"
          mandatory
          @change="changeRoleIndex"
      >
        <v-btn
          class="flex-grow-1"
        >user</v-btn>
        <v-btn
          class="flex-grow-1"
        >admin</v-btn>
        <v-btn
          v-if="$store.persist.d.role === 'super' || $store.persist.d.role === 'dev'"
          class="flex-grow-1"
        >super</v-btn>
        <v-btn
          v-if="$store.persist.d.role === 'dev'"
          class="flex-grow-1"
        >dev</v-btn>
      </v-btn-toggle>

      <div class="d-flex flex-row align-center mt-4">
        <div class="subtitle-1" style="width: 100px; line-height: 1;">Количество доступных запусков ИИ</div>
        <v-text-field
          v-model="localUser.llm_runs_limits_count"
          :rules="rulesLimitCount"
          outlined
          dense
          lang="ru"
          rows="2"
          auto-grow
          type="number"
          :hint="limitCountHint"
          persistent-hint
          ref="llm_runs_limits_count"
        />
      </div>

      <v-expand-transition>
        <div v-if="isError" class="pt-4">
          <v-alert type="error">
            Не все поля введены верно
          </v-alert>
        </div>
      </v-expand-transition>
      <v-btn depressed color="primary" block @click="save()" class="my-4">Сохранить</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputUser',
  props: {
    user: Object,
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    companies: {
      type: Array,
      required: false,
    },
  },
  data: () => ({
    localUser: null,
    isError: false,
    roleIndex: 0,
    isShowPassword: false,
  }),
  watch: {
    user: {
      immediate: true,
      handler() {
        this.localUser = JSON.parse(JSON.stringify(this.user));
        // TODO
        this.roleIndex = ['user', 'admin', 'super', 'dev'].indexOf(this.user.role || 'user');
      },
    },
  },
  computed: {
    rulesName() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
    rulesLogin() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
    rulesPassword() {
      return [
        v => !!v || 'Поле обязательно для заполнения',
      ];
    },
    rulesLimitCount() {
      return [this.validateInteger, this.validateRange];
    },
    limitCountHint() {
      const old = this.user.llm_runs_limits_count > 0 ? parseInt(this.user.llm_runs_limits_count) : 0;
      const cur = this.user._freeLLMLimits > 0 ? this.user._freeLLMLimits : 0;
      const max = this.user._freeLLMLimits !== undefined ? (old + cur) : 9999;
      return `Количество незанятых доступных запусков ИИ: ${max}`
    },
  },
  methods: {
    validateInteger(value) {
      const integerPattern = /^-?\d+$/;
      if (integerPattern.test(value)) {
        return true;
      }
      return 'Введите целое число';
    },
    validateRange(value) {
      const num = parseInt(value, 10);
      const old = this.user.llm_runs_limits_count > 0 ? parseInt(this.user.llm_runs_limits_count) : 0;
      const cur = this.user._freeLLMLimits > 0 ? this.user._freeLLMLimits : 0;
      const max = this.user?._freeLLMLimits ? (old + cur) : 9999;
      if (num >= -1 && num <= max) {
        return true;
      }
      return `Число должно быть в диапазоне от -1 до ${max}`;
    },

    changeRoleIndex() {
      // TODO
      this.localUser.role = ['user', 'admin', 'super', 'dev'][this.roleIndex];
    },
    save() {
      // if (!this.required) {
      //   this.isError = false;
      //   this.$emit('save', this.localProfile);
      //   return;
      // }

      // TODO: check changes exist

      this.$refs.name.validate();
      this.$refs.login.validate();
      this.$refs.llm_runs_limits_count.validate();

      if (!this.isEdit) {
        this.$refs.password.validate();
      }

      if (!this.$refs.name.valid ||
          !this.$refs.login.valid ||
          !this.$refs.llm_runs_limits_count.valid ||
          (!this.isEdit && !this.$refs.password.valid)) {
        this.isError = true;
        return;
      }

      this.isError = false;

      this.$emit('save', this.localUser);
    },
  },
};
</script>

<style scoped>

</style>
