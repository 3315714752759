<script>
import InputCompany from "@/ui/components/InputCompany.vue";
import ViewDialog from "@/ui/components/ViewDialog.vue";
import CardCompany from "@/ui/components/CardCompany.vue";

export default {
  name: "ViewCompanies",
  components: {CardCompany, ViewDialog, InputCompany},
  data: () => ({
    users: null,
    companies: null,
    currentCompany: null,

    isShowCompanyAdd: false,
    isShowCompanyEdit: false,

    companyEdit: null,
  }),
  computed: {
    companyId() {
      return this.$store.persist.d.companyId;
    },
  },
  methods: {
    startCreateCompany() {
      this.isShowCompanyAdd = true;
      this.companyEdit = {
        name: '',
        description: '',
      };
    },
    startUpdateCompany(company) {
      this.isShowCompanyEdit = true;
      this.companyEdit = {...company};
    },

    async createCompany(company) {
      this.companyEdit.name = company.name;
      this.companyEdit.description = company.description;
      this.companyEdit.llm_runs_limits_count = company.llm_runs_limits_count;

      const response = await this.$api.main.postRequest('models/company', {
        name: this.companyEdit.name,
        description: this.companyEdit.description,
        llm_runs_limits_count: this.companyEdit.llm_runs_limits_count,
      });
      if (!response.success) {
        if (response.error === 'exist name') {
          this.$api.app.snackError('Компания с таким названием уже существует');
          return;
        }
      }
      await this.init();
      this.isShowCompanyAdd = false;
    },
    async updateCompany(company) {
      this.companyEdit.id = company.id;
      this.companyEdit.name = company.name;
      this.companyEdit.description = company.description;
      this.companyEdit.llm_runs_limits_count = company.llm_runs_limits_count;

      const response = await this.$api.main.postRequest('models/company', {
        id: this.companyEdit.id,
        name: this.companyEdit.name,
        description: this.companyEdit.description,
        llm_runs_limits_count: this.companyEdit.llm_runs_limits_count,
      });
      if (!response.success) {
        if (response.error === 'exist name') {
          this.$api.app.snackError('Компания с таким названием уже существует');
          return;
        }
      }
      await this.init();
      this.isShowCompanyEdit = false;
    },
    deleteCompany(company) {
      this.$api.app.openConfirmDialog({
        title: 'Удаление компании',
        text: 'Вы уверены, что хотите удалить эту компанию?',
        ok: async () => {
          await this.$api.main.deleteRequest('models/company', {id: company.id});
          await this.init();
        },
      });
    },

    async fetchCompanies() {
      // TODO: loading and error
      this.companies = (await this.$api.main.getRequest('models/companies')).data.map(el => ({
        ...el,
        _freeLLMLimits: null,
      }));
    },

    async fetchUsers() {
      try {
        this.users = (await this.$api.main.getRequest('models/users')).data;
      } catch (e) {
        console.error(e);
      }
    },

    async init() {
      await this.fetchUsers();

      await this.fetchCompanies();

      if (this.users) {
        this.companies = this.companies.map(company => {

          let freeLLMLimits = company.llm_runs_limits_count;

          if (freeLLMLimits === -1) {
            company._freeLLMLimits = -1;
            return company;
          }

          if (freeLLMLimits === 0) {
            company._freeLLMLimits = 0;
            return company;
          }

          this.users.forEach(user => {
            if (user.company_id !== company.id) {
              return;
            }

            if (user.llm_runs_limits_count > 0) {
              freeLLMLimits -= user.llm_runs_limits_count;
            }
          });

          if (freeLLMLimits < 0) {
            freeLLMLimits = 0;
          }

          company._freeLLMLimits = freeLLMLimits;
          return company;
        });
      }
    },
  },
  async created() {
    await this.init();
  },
}
</script>

<template>
  <div class="mb-4">
    <div class="d-flex justify-space-between align-center">
      <h3>Компании</h3>
      <v-spacer/>
      <v-btn depressed @click="startCreateCompany">
        Добавить
      </v-btn>
    </div>

    <template v-if="companies && companies.length">
      <CardCompany
        v-for="company of companies"
        :key="company.id"
        :name="company.name"
        :description="company.description"
        :createdAt="company.createdAt"
        :updatedAt="company.updatedAt"
        :id="company.id"
        :item="company"
        @update="startUpdateCompany(company)"
        @delete="deleteCompany(company)"
      />
    </template>

    <div v-else>Компании отсутствуют</div>

    <ViewDialog v-model="isShowCompanyAdd" title="Добавление компании">
      <InputCompany :company="companyEdit" @save="createCompany"/>
    </ViewDialog>

    <ViewDialog v-model="isShowCompanyEdit" title="Редактирование компании">
      <InputCompany :company="companyEdit" @save="updateCompany"/>
    </ViewDialog>
  </div>

</template>

<style scoped>

</style>
