<template>
  <LayoutOneColumn :maxWidth="850">
<!--    v-if="$store.persist.d.role === 'super' || $store.persist.d.role === 'dev'"-->
    <ViewCompanies/>
    <ViewUsers class="mt-4"/>
  </LayoutOneColumn>
</template>

<script>
import LayoutOneColumn from "@/ui/layouts/LayoutOneColumn.vue";
import ViewCompanies from "@/ui/components/ViewCompanies.vue";
import ViewUsers from "@/ui/components/ViewUsers.vue";

export default {
  name: "PageControlCompaniesPanel",
  components: {ViewUsers, ViewCompanies, LayoutOneColumn},
}
</script>
